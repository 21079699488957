<template>
  <a-modal
    :title="isTransfer ? '移交公司' : '离职'"
    :visible="visible"
    @ok="whenSubmit"
    @cancel="$emit('cancel')"
    :destroyOnClose="true"
    width="700px"
    :okText="'确定'"
  >
    <a-spin :spinning="spinning">
      <a-form-model
        class="formInfo"
        ref="transmitForm"
        layout="horizontal"
        :model="transmitForm"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
        labelAlign="left"
      >
        <a-form-model-item :label="isTransfer ? '管理员' : '交接人'">
          <a-select
            placeholder="请选择"
            show-search
            allowClear
            @search="handleSearch"
          >
            <a-select-option
              v-for="item of userList"
              :value="item.userId"
              :key="item.userId"
              @click="whenChangeParent(item)"
              >{{ item.fullName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
  </a-modal>
</template>

<script>
import {
  companyUserTransmit,
  untyingCompanyUser,
  getCurrentCompanyUsersList,
} from "/src/api/companyuser.js";
import { switchCompanyAsync } from "@/api/account";
export default {
  props: {
    visible: { type: Boolean, default: () => false }, //是否显示组件
    isTransfer: { type: Boolean, default: () => true }, //是否移交公司
    form: { type: Object, default: () => {} }, //组件表单数据源
  },
  data() {
    return {
      spinning: false,
      userList: [], //下拉列表框数据源
      selectUserId: 0, //下拉列表选择用户ID
      transmitForm: {
        userId: null,
      },
    };
  },
  /** 组件生命周期组件创建后事件 */
  created() {
    this.handleSearch("");
  },
  methods: {
    /** 确认事件 */
    whenSubmit(res) {
      if (this.selectUserId == null || this.selectUserId == 0) {
        this.$message.warning(
          this.isTransfer ? "请选择移交用户" : "请选择交接人"
        );
        return false;
      }
      this.spinning = true;
      if (!this.isTransfer) {
        this.setUntyingCompanyUser();
      } else {
        this.setCompanyUserTransmit();
      }
    },
    /**
     * 选择器选中项事件
     * @param {string} value Select选中项值
     */
    whenChangeParent(item) {
      this.selectUserId = item.userId;
    },
    /**
     * 下拉框搜索事件
     * @param {string} value 搜索关键字
     */
    handleSearch(value) {
      var param = {
        filter: value,
        isFilterInvitationStatus: true,
      };
      getCurrentCompanyUsersList(param).then((res) => {
        var datas = [];
        res.items.forEach((item) => {
          if (item.userId !== this.transmitForm.userId) datas.push(item);
        });
        this.userList = datas;
      });
    },
    /** 员工离职 */
    setUntyingCompanyUser() {
      var parm = {
        userId: this.transmitForm.userId,
        acceptUserId: this.selectUserId,
        companyUserId: this.transmitForm.id,
      };
      untyingCompanyUser(parm)
        .then(() => {
          this.$emit("submited");
          this.$emit("onSearch");
          this.$message.success(this.transmitForm.fullName + "已离职");
          this.spinning = false;
          const userInfo = this.$store.getters.getUserInfo;
          var currentCompany = userInfo.currentCompany;
          var companyList = [...userInfo.companyList];
          if (userInfo.id == this.transmitForm.userId) {
            userInfo.companyList = companyList.filter(
              (p) => p.id != currentCompany.id
            );
            if (userInfo.companyList.length > 0) {
              switchCompanyAsync(userInfo.companyList[0]);
            } else {
              userInfo.currentCompany = null;
              userInfo.companyId = null;
              userInfo.companyName = null;
              userInfo.tenantId = null;
              this.$store.commit("SET_USER_INFO", userInfo);
              this.$router.push({
                path: "/home",
              });
            }
          }
        })
        .catch(() => {
          this.$message.error("离职异常");
          this.spinning = false;
        });
    },
    /** 移交公司 */
    setCompanyUserTransmit() {
      companyUserTransmit(this.selectUserId)
        .then((res) => {
          const userInfo = this.$store.getters.getUserInfo;
          this.$emit("submited");
          this.$emit("onSearch");
          userInfo.isCompanyAdmin = res.isCompanyAdmin;
          userInfo.roles = res.res;
          userInfo.permissions = res.permissions;
          userInfo.userInfo.isCompanyAdmin = res.isCompanyAdmin;
          userInfo.userInfo.roles = res.res;
          userInfo.userInfo.permissions = res.permissions;
          this.$store.commit("SET_USER_INFO", userInfo);
          this.$message.success("公司转交成功");
          this.spinning = false;
        })
        .catch(() => {
          this.$message.error("公司转交异常");
          this.spinning = false;
        });
    },
  },
  watch: {
    form: {
      handler() {
        this.transmitForm = this.form;
        this.handleSearch();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
