var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bgStyle"},[_c('div',{staticClass:"title"},[_vm._v("公司成员")]),_c('a-input-search',{staticStyle:{"width":"200px","margin-right":"12px"},attrs:{"placeholder":"员工姓名、手机号、工号"},on:{"search":_vm.onSearch}}),_c('a-button',{staticClass:"createBtn",attrs:{"type":"primary"},on:{"click":_vm.whenInvite}},[_vm._v("邀请新员工 ")]),_c('a-table',{staticClass:"table",staticStyle:{"margin-top":"12px"},attrs:{"columns":_vm.columns,"data-source":_vm.data,"rowKey":"userId","pagination":_vm.pagination,"loading":_vm.loading},on:{"change":_vm.whenChangePage},scopedSlots:_vm._u([{key:"operation",fn:function(text, record){return [(_vm.isShowHandoverCompany(record))?_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handoverCompany(record)}}},[_vm._v(" 移交公司 ")]):_vm._e(),(record.invitateState == 1)?_c('a-button',{staticClass:"btn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.inviteAgain(record)}}},[_vm._v(" 通知 ")]):_vm._e(),(record.invitateState == 3 || record.invitateState == 1)?_c('a-button',{staticClass:"btn",attrs:{"type":"danger"},on:{"click":function($event){return _vm.deleteMember(record)}}},[_vm._v(" 删除 ")]):_vm._e(),(
          record.activeState == 1 &&
          record.companyUserRoleName !== '超级管理员'
        )?_c('a-button',{attrs:{"type":"dashed"},on:{"click":function($event){return _vm.resign(record)}}},[_vm._v("离职 ")]):_vm._e()]}},{key:"invitateState",fn:function(text, record){return [(record.invitateState == 0)?_c('p',[_vm._v("创建邀请")]):_vm._e(),(record.invitateState == 1)?_c('p',[_vm._v("邀请中")]):_vm._e(),(record.invitateState == 2)?_c('p',[_vm._v("已加入")]):_vm._e(),(record.invitateState == 3)?_c('p',[_vm._v("拒绝邀请")]):_vm._e()]}},{key:"activeState",fn:function(text, record){return [(record.activeState == -1)?_c('p',[_vm._v("已离职")]):_vm._e(),(record.activeState == 0)?_c('p',[_vm._v("未激活")]):_vm._e(),(record.activeState == 1)?_c('p',[_vm._v("已激活")]):_vm._e()]}},{key:"no",fn:function(text, record, index){return _c('div',{},[_vm._v(" "+_vm._s((_vm.pagination.current - 1) * _vm.pagination.pageSize + index + 1)+" ")])}}])}),_c('CompanyInvitePopup',{ref:"inviteInfoRef",attrs:{"visible":_vm.inviteInfoRef.visible,"form":this.inviteInfoRef.form},on:{"onSearch":_vm.onSearch,"submited":function () {
        this$1.inviteInfoRef.visible = false;
        this$1.onSearch();
      },"cancel":function () {
        this$1.inviteInfoRef.visible = false;
      }}}),_c('transfer-company',{ref:"transfercompany",attrs:{"visible":_vm.transfercompanyCtr.visible,"title":_vm.transfercompanyCtr.title,"labelTitle":_vm.transfercompanyCtr.labelTitle,"isTransfer":_vm.transfercompanyCtr.isTransfer,"form":_vm.transfercompanyCtr.form},on:{"onSearch":_vm.onSearch,"submited":function () {
        this$1.transfercompanyCtr.visible = false;
      },"cancel":function () {
        this$1.transfercompanyCtr.visible = false;
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }