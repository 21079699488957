<template>
  <div class="bgStyle">
    <div class="title">公司成员</div>
    <a-input-search
      placeholder="员工姓名、手机号、工号"
      style="width: 200px; margin-right: 12px"
      @search="onSearch"
    />
    <a-button type="primary" class="createBtn" @click="whenInvite"
      >邀请新员工
    </a-button>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="userId"
      style="margin-top: 12px"
      class="table"
      @change="whenChangePage"
      :pagination="pagination"
      :loading="loading"
    >
      <template slot="operation" slot-scope="text, record">
        <a-button
          type="primary"
          class="btn"
          @click="handoverCompany(record)"
          v-if="isShowHandoverCompany(record)"
        >
          移交公司
        </a-button>
        <a-button
          type="primary"
          class="btn"
          @click="inviteAgain(record)"
          v-if="record.invitateState == 1"
        >
          通知
        </a-button>
        <a-button
          type="danger"
          class="btn"
          @click="deleteMember(record)"
          v-if="record.invitateState == 3 || record.invitateState == 1"
        >
          删除
        </a-button>
        <a-button
          type="dashed"
          @click="resign(record)"
          v-if="
            record.activeState == 1 &&
            record.companyUserRoleName !== '超级管理员'
          "
          >离职
        </a-button>
      </template>
      <template slot="invitateState" slot-scope="text, record">
        <p v-if="record.invitateState == 0">创建邀请</p>
        <p v-if="record.invitateState == 1">邀请中</p>
        <p v-if="record.invitateState == 2">已加入</p>
        <p v-if="record.invitateState == 3">拒绝邀请</p>
      </template>
      <template slot="activeState" slot-scope="text, record">
        <p v-if="record.activeState == -1">已离职</p>
        <p v-if="record.activeState == 0">未激活</p>
        <p v-if="record.activeState == 1">已激活</p>
      </template>
      <div slot="no" slot-scope="text, record, index">
        {{ (pagination.current - 1) * pagination.pageSize + index + 1 }}
      </div>
    </a-table>
    <CompanyInvitePopup
      ref="inviteInfoRef"
      :visible="inviteInfoRef.visible"
      :form="this.inviteInfoRef.form"
      @onSearch="onSearch"
      @submited="
        () => {
          this.inviteInfoRef.visible = false;
          this.onSearch();
        }
      "
      @cancel="
        () => {
          this.inviteInfoRef.visible = false;
        }
      "
    />
    <transfer-company
      ref="transfercompany"
      :visible="transfercompanyCtr.visible"
      :title="transfercompanyCtr.title"
      :labelTitle="transfercompanyCtr.labelTitle"
      :isTransfer="transfercompanyCtr.isTransfer"
      :form="transfercompanyCtr.form"
      @onSearch="onSearch"
      @submited="
        () => {
          this.transfercompanyCtr.visible = false;
        }
      "
      @cancel="
        () => {
          this.transfercompanyCtr.visible = false;
        }
      "
    />
  </div>
</template>
<script>
// 员工
const columns = [
  {
    title: "工号",
    dataIndex: "employeeNo",
  },
  {
    title: "姓名",
    dataIndex: "fullName",
  },
  {
    title: "手机号",
    dataIndex: "phoneNumber",
  },
  {
    title: "角色",
    dataIndex: "companyUserRoleName",
  },
  {
    title: "邀请状态",
    dataIndex: "invitateState",
    scopedSlots: { customRender: "invitateState" },
  },
  {
    title: "状态",
    dataIndex: "activeState",
    scopedSlots: { customRender: "activeState" },
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
  },
];
import CompanyInvitePopup from "./companyInvitePopup.vue";
import moment from "moment";
import {
  deleteCompanyUsers,
  companyInviteAgain,
  getCurrentCompanyUsersList,
} from "@/api/companyuser";
import TransferCompany from "./TransferCompany.vue";
export default {
  created() {
    this.getCurrentCompanyUsersList();
  },
  data() {
    return {
      data: [],
      columns,
      selectedRowKeys: [],
      /** 移交公司/离职 组件参数 */
      transfercompanyCtr: {
        isTransfer: true,
        visible: false,
        form: {},
      },
      /**列表分页参数设置 */
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
      },
      /** 邀请新员工 组件参数 */
      inviteInfoRef: {
        form: {},
        visible: false,
      },
      loading: false,
    };
  },
  components: {
    CompanyInvitePopup,
    TransferCompany,
  },
  methods: {
    /**
     * 移交公司按钮显示判断
     * @param {object} entity 公司信息
     */
    isShowHandoverCompany(entity) {
      var currUser = this.$store.getters.getUserInfo;
      return (
        entity.companyUserRoleName == "超级管理员" &&
        entity.userId == currUser.id
      );
    },
    /**
     * 员工姓名/手机号查询框查询事件
     * @param {string} value [员工姓名/手机号]
     */
    onSearch(value) {
      var parm = {
        filter: value,
      };
      this.getCurrentCompanyUsersList(parm);
    },
    /**
     * 查询当前公司成员
     * @param {object} parm 公司信息
     */
    getCurrentCompanyUsersList(parm) {
      var _this = this;
      this.loading = true;
      if (!parm) {
        parm = {};
      }
      parm.maxResultCount = this.pagination.pageSize;
      parm.skipCount = (this.pagination.current - 1) * this.pagination.pageSize;
      getCurrentCompanyUsersList(parm)
        .then((res) => {
          _this.data = res.items;
          var pagination = { ...this.pagination };
          pagination.total = res.totalCount;
          this.pagination = pagination;
          _this.loading = false;
        })
        .catch(() => {
          _this.$message.error("获取公司成员异常，请重试～！");
          _this.loading = false;
        });
    },
    /**
     * 分页切换事件
     * @param {Object} pagination 分页对象
     */
    whenChangePage(pagination) {
      this.pagination.current = pagination.current;
      this.getCurrentCompanyUsersList();
    },
    /**
     * 移交公司
     * @param {object} reconds 移交用户
     */
    handoverCompany(reconds) {
      var entity = JSON.parse(JSON.stringify(reconds));
      this.transfercompanyCtr.form = entity;
      this.transfercompanyCtr.visible = true;
      this.transfercompanyCtr.isTransfer = true;
    },
    /**
     * 再次邀请
     * @param {object} reconds 再次邀请用户
     */
    inviteAgain(reconds) {
      var noticeTime = moment(reconds.noticeTime).format("YYYY-MM-DD");
      var currentTime = moment(new Date()).format("YYYY-MM-DD");
      if (noticeTime == currentTime) {
        this.$message.info("今天已发送通知，请明天再发送");
        return;
      }
      var parm = {
        id: reconds.id,
        phoneNumber: reconds.phoneNumber,
        employeeNo: reconds.employeeNo,
      };
      companyInviteAgain(parm).then(() => {
        reconds.noticeTime = currentTime;
        this.$message.info("已再次发送邀请通知");
      });
    },
    /**
     * 删除成员
     * @param {object} reconds 删除成员
     */
    deleteMember(reconds) {
      deleteCompanyUsers(reconds.id).then(() => {
        this.$message.info("删除成功");
        this.onSearch();
      });
    },
    /**
     * 离职
     * @param {object} reconds 离职用户
     */
    resign(reconds) {
      var entity = JSON.parse(JSON.stringify(reconds));
      this.transfercompanyCtr.form = entity;
      this.transfercompanyCtr.visible = true;
      this.transfercompanyCtr.isTransfer = false;
    },
    /** 邀请新员工 */
    whenInvite() {
      this.inviteInfoRef.form = {};
      this.inviteInfoRef.visible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  margin-top: 20px;
}
.title {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
  margin-bottom: 12px;
}
.btn {
  margin-right: 10px;
}
</style>
