<template>
  <div class="bgStyle">
    <div class="pageTitle">公司详情</div>
    <div class="companyInfo">
      <div style="width: 100%">
        <a-row :gutter="16" style="margin-bottom: 28px">
          <a-col>
            <a-space size="16">
              <span class="p">公司名称：{{ currentCompany }}</span>
            </a-space>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <span class="p">行业类型：{{ companyIndustryCodeName }}</span>
          </a-col>
          <a-col :span="12">
            <span class="p">企业规模：{{ companyStaffCodeName }}</span>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import { getCompany } from "@/api/company";
export default {
  data() {
    return {
      registerForm: {},
    };
  },
  created() {
    var _this = this;
    var currentCompany = _this.$store.getters.getUserInfo.currentCompany;
    if (currentCompany) {
      getCompany(currentCompany.id)
        .then((res) => {
          _this.registerForm = res;
        })
        .catch(() => {
          _this.$message.error("获取公司信息失败，请重试～！");
        });
    } else {
      _this.$message.error("当前未选择公司");
    }
  },
  methods: {},
  computed: {
    currentCompany() {
      return this.$store.getters.getUserInfo
        ? this.$store.getters.getUserInfo.companyName
        : "";
    },
    companyIndustryCodeName() {
      return this.registerForm.companyIndustryCodeName
        ? this.registerForm.companyIndustryCodeName
        : "";
    },
    companyStaffCodeName() {
      return this.registerForm.companyStaffCodeName
        ? this.registerForm.companyStaffCodeName
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.companyInfo {
  display: flex;
  margin-top: 16px;
  span.p {
    font-size: 14px;
  }
  .companyName {
    font-size: 14px;
    font-weight: 600;
  }
}
.select {
  width: 150px;
}
.title {
  font-size: 14px;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.detailForm {
  margin-top: 10px;
  flex: 1;
  .editBtn {
    color: $blue_color;
    cursor: pointer;
    z-index: 999;
  }
  ::v-deep.ant-form-item {
    margin-bottom: 10px;
  }
  .businessLicense {
    width: 150px;
  }  
}
</style>
