<template>
  <a-modal
    title="发送公司邀请"
    :destroyOnClose="true"
    :visible="visible"
    :confirmLoading="loading"
    @ok="whenSubmit"
    @cancel="$emit('cancel')"
    :okText="'确定'"
  >
    <a-form-model
      id="components-form-demo-normal-login"
      class="formInfo"
      ref="inviteForm"
      layout="horizontal"
      :rules="rules"
      :model="inviteForm"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
      labelAlign="left"
    >
      <a-form-model-item label="工号" prop="employeeNo">
        <a-input
          type="text"
          placeholder="请输入员工工号"
          v-model="inviteForm.employeeNo"
          autocomplete="off"
        >
        </a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号" prop="phoneNumber">
        <a-input
          type="text"
          placeholder="请输入手机号"
          v-model="inviteForm.phoneNumber"
          autocomplete="off"
        />
      </a-form-model-item>
      <a-form-model-item label="角色" prop="companyUserRoleId">
        <a-select :default-value="inviteForm.companyUserRoleId">
          <a-select-option
            v-for="item of listRoles"
            :value="item.id"
            :key="item.id"
            @click="whenChangeParent(item)"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { isTelephone } from "@/utils/tools";
import { createCompanyUser } from "@/api/companyuser";
import { getRolesList } from "@/api/roles.js";
export default {
  props: {
    visible: { type: Boolean, default: () => false },
    form: { type: Object, default: () => {} },
  },
  created() {
    this.loading = false;
    var _this = this;
    getRolesList().then((res) => {
      var datas = [];
      res.items.forEach((item) => {
        if (item.name !== "超级管理员") datas.push(item);
      });
      _this.listRoles = datas;
    });
  },
  data() {
    let isPhone = (rule, value, callback) => {
      isTelephone(rule, value, callback);
    };
    let employeeNoValidator = (rule, value, callback) => {
      if (!value) callback("请输入员工工号");
      if (value.indexOf(" ") > -1) callback("员工工号不能包含空格");
      callback();
    };
    return {
      listRoles: [],
      inviteForm: {
        employeeNo: "",
        phoneNumber: "",
        companyUserRoleId: "",
      },
      rules: {
        phoneNumber: [{ required: true, validator: isPhone, trigger: "blur" }],
        employeeNo: [
          { required: true, validator: employeeNoValidator, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    /**
     * 选择器选中项事件
     * @param {string} value Select选中项值
     */
    whenChangeParent(item) {
      this.inviteForm.companyUserRoleId = item.id;
    },
    /** 邀请新员工确认事件 */
    whenSubmit() {
      let _this = this;
      this.$refs.inviteForm.validate((valid) => {
        if (valid) {
          var userInfo = _this.$store.getters.getUserInfo;
          if (_this.inviteForm.phoneNumber == userInfo.phoneNumber) {
            this.$message.warning("不能邀请用户本身~");
            return;
          }
          this.loading = true;
          var parm = {
            phoneNumber: _this.inviteForm.phoneNumber,
            employeeNo: _this.inviteForm.employeeNo,
            fullName: _this.inviteForm.employeeNo,
            companyUserRoleId: _this.inviteForm.companyUserRoleId,
          };
          createCompanyUser(parm)
            .then(() => {
              this.loading = false;
              this.$emit("submited");
              this.$emit("onSearch");
              this.$message.success(
                "工号:" + this.inviteForm.employeeNo + "邀请成功"
              );
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
  watch: {
    form: {
      handler() {
        this.inviteForm = this.form;
        if (this.listRoles) {
          this.listRoles.forEach((item) => {
            if (item.name == "普通成员")
              this.inviteForm.companyUserRoleId = item.id;
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.companyName {
  font-size: 14px;
  font-weight: 600;
}
</style>
