<template>
  <div>
    <CompanyDetail />
    <CompanyNumber />
  </div>
</template>

<script>
import CompanyDetail from "./components/CompanyDetail";
import CompanyNumber from "./components/CompanyNumber";
export default {
  components: {
    CompanyDetail,
    CompanyNumber,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
